<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md">
            <h2 class="text-2xl text-center font-bold text-orange-500 mb-8">{{ $translate('products.manage_products.title') }}</h2>
            <div class="mb-4 flex justify-between items-center gap-4">
              <input
                  type="text"
                  :placeholder="$translate('placeholders.search.by_product_name')"
                  class="p-2 rounded bg-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  v-model="searchQuery"
              />
            </div>
            <div class="flex flex-col">
              <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                <div class="py-3 font-bold px-4 w-1/5">{{$translate('id')}}</div>
                <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 font-bold px-4 w-1/5">{{$translate('client')}}</div>
                <div class="py-3 font-bold px-4 w-2/5">{{$translate('product')}}</div>
                <div class="py-3 font-bold px-4 w-1/5">{{$translate('description')}}</div>
                <div class="py-3 font-bold px-4 w-1/5">{{$translate('brand_name')}}</div>
                <div class="py-3 font-bold px-4 w-1/5">{{$translate('products.manage_products.contact_number')}}</div>
                <div class="py-3 font-bold px-4 w-1/5">{{ $translate('action') }}</div>
              </div>
              <div v-for="(product) in paginatedProducts" :key="product.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                <div class="py-3 px-4 w-1/5">{{ product.product_id }}</div>
                <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 w-1/5">{{ product.client?.company_name}}</div>
                <div class="py-3 px-4 w-2/5">{{ product.name }}</div>
                <div class="py-3 px-4 w-1/5">{{ product.description }}</div>
                <div class="py-3 px-4 w-1/5">{{ product.brand }}</div>
                <div class="py-3 px-4 w-1/5">{{ product.contactNumber }}</div>
                <div class="flex justify-center max-w-xs pb-4">
                  <ButtonComponent @click="editProduct(product)" class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2">
                    <span class="material-icons">edit</span>
                  </ButtonComponent>
                  <ButtonComponent
                    @click="confirmToDelete(product.id)"
                    class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2"
                    >
                    <span class="material-icons"> delete </span>
                  </ButtonComponent>
                </div>
              </div>
              <EmptyStateComponent
                  :show="Object.keys(paginatedProducts).length === 0"
                  :message="$translate('products.manage_products.no_products')"
              />
            </div>
            <PaginationComponent
                v-show="totalPages > 1"
                :currentPage="currentPage"
                :totalPages="totalPages"
                @update:currentPage="currentPage = $event"
                />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
          <h2 class="text-2xl font-bold text-orange-500 mb-4">{{$translate('products.manage_products.edit_product')}}</h2>
          <form @submit.prevent="handleUpdateProduct">
            <div class="mb-4">
              <input
                  type="text"
                  v-model="productToEdit.name"
                  :placeholder="$translate('placeholders.product_name')"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
            <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
              <select
                  v-model="productToEdit.clientId"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <option value="" disabled selected>{{$translate('select_client')}}</option>
                <option v-for="client in clients" :key="client.id" :value="client.id">
                  {{ client.company_name }}
                </option>
              </select>
            </div>
            <div class="mb-4">
              <input
                  type="text"
                  v-model="productToEdit.brand"
                  :placeholder="$translate('placeholders.brand_name')"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  disabled
              />
            </div>
            <div class="mb-4">
              <input
                  type="text"
                  v-model="productToEdit.description"
                  :placeholder="$translate('placeholders.product_description')"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
            <div class="mb-4">
              <input
                  type="text"
                  v-model="productToEdit.contactNumber"
                  :placeholder="$translate('placeholders.contact_number')"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
            <div class="flex justify-center mt-6">
              <ButtonComponent
                  type="submit"
                  class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
              >
                {{ $translate('update') }}
              </ButtonComponent>
            </div>
          </form>
          <ButtonComponent
              @click="closeModal"
              class="bg-orange-600 text-white px-4 py-2 rounded mt-4 hover:bg-orange-800"
          >
            {{ $translate('close') }}
          </ButtonComponent>
        </div>
      </div>
    </transition>
    <ConfirmationModalComponent
        title="Delete Confirmation"
        message="Are you sure you want to delete this product?"
        :isOpen="isConfirmationModalOpen"
        @confirm="deleteProductModal"
        @close="isConfirmationModalOpen = false"
    />
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import EmptyStateComponent from "@/components/Fields/EmptyStateComponent.vue";

export default {
  components: {EmptyStateComponent, ConfirmationModalComponent, PaginationComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      isModalOpen: false,
      productToEdit: {
        id: null,
        name: '',
        description: '',
        brand: '',
        contactNumber: '',
        clientId: "",
      },
      products: [],
      role: this.$usergroup,
      currentPage: 1,
      recordsPerPage: 5,
      searchQuery: '',
      isConfirmationModalOpen: false,
      confirmationModalAction: '',
      confirmationModalTitle: '',
      productIdToDelete : '',
    };
  },
  methods: {
    confirmToDelete(id) {
      this.productIdToDelete = id;
      this.confirmationModalAction = this.$translate('delete');
      this.confirmationModalTitle = this.$translate('delete');
      this.isConfirmationModalOpen = true;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.productToEdit = {};
    },
    editProduct(product) {
      this.productToEdit = {
        ...product,
        clientId: product.client ? product.client.id : "", // Ensure it's correctly assigned
      };
      this.openModal();
    },
    deleteProductModal(){
      this.deleteProduct(this.productIdToDelete);
      this.closeDeleteModal();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.groupIdToDelete = null;
    },
    async fetchProducts() {
      try {
        let response = await this.$axios.request({
          url: '/products/fetch/all',
          method: 'GET',
        });
        let brands = response.data.brands;
        this.clients = response.data.clients;
        if(this.role === this.$defaultUsergroups.superadmin) {
          this.products = response.data.products.map(product => ({
            id: product.id,
            name: product.name,
            product_id: product.product_id,
            brand: brands[product.brand_id].name,
            client: this.clients[product.user_id],
            description: product.description,
            contactNumber: product.contact_number,
          }));
        }
        else {
          this.products = response.data.products.map(product => ({
            id: product.id,
            name: product.name,
            product_id: product.product_id,
            brand: brands[product.brand_id],
            description: product.description,
            contactNumber: product.contact_number,
          }));
        }
      } catch (error) {
        console.error("Failed to fetch products:", error.response || error);
        this.$toast.error(this.$translate('products.manage_products.messages.error'));
      }
    },
    async handleUpdateProduct() {
      const updatedProduct = { ...this.productToEdit };

      try {
        const response = await this.$axios.request({
          url: `/products/update/${updatedProduct.id}`,
          method: "PUT",
          data: updatedProduct,
        });

        console.log("Update Response:", response.data);

        if (response.data.status === "success" && response.data.product) {

          const updatedProductData = {
            product_id: response.data.product.product_id,
            name: response.data.product.name,
            description: response.data.product.description,
            brand: response.data.brands[response.data.product.brand_id],
            contactNumber: response.data.product.contact_number,
            client: this.clients[response.data.product.user_id],
          };
          const index = this.products.findIndex((product) => product.product_id === updatedProductData.product_id);
          if (index !== -1) {
            this.products.splice(index, 1, updatedProductData);
          } else {
            console.warn("Product not found in the list:", updatedProductData);
          }
          this.closeModal();
          this.$toast.success(this.$translate('products.manage_products.messages.success'));
        } else {
          console.error("Unexpected response format:", response.data);
          this.$toast.error(this.$translate('products.manage_products.messages.error'));
        }
      } catch (error) {
        console.error("Failed to update product:", error.response || error);
        this.$toast.error(this.$translate('products.manage_products.messages.error'));
      }
    },
    async deleteProduct(productId) {
      if (this.deletingProduct) return;
      this.deletingProduct = true;
        try {
          let response = await this.$axios.request({
            url: `/products/delete/${productId}`,
            method: "DELETE",
          });

          if (response.data.status === "success") {
            this.products = this.products.filter(product => product.id !== productId);
            this.$toast.success('Product deleted successfully');
          } else {
            console.error("Unexpected response format:", response.data);
            this.$toast.error(response.data.error);
          }
        } catch (error) {
          console.error("Failed to delete product:", error.response || error);
          this.$toast.error(this.$translate('products.manage_products.messages.delete_error'));
        }
      this.deletingProduct = false;
    },
  },
  mounted() {
    this.fetchProducts();
  },
  computed:{
    filteredProducts() {
      return this.products.filter(product =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    totalPages(){
      if(this.filteredProducts.length === 0) return 1;
      return Math.ceil(this.products.length / this.recordsPerPage);
    },
    paginatedProducts(){
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredProducts.slice(start, start + this.recordsPerPage);
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
