<template>
  <div class="flex justify-center items-center bg-gray-100 min-h-screen">
    <div class="container bg-[#eeeeee] text-white p-8 shadow-lg rounded-lg overflow-y-auto">
      <h2 class="text-6xl text-orange-500 font-bold mb-4 text-center animate-bounce">404</h2>
      <p class="text-center text-lg text-black mb-6">
        Oops! The page you're looking for doesn't exist.
      </p>
      <ButtonComponent @click="goHome" text="Go Home">Go Back</ButtonComponent>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";

export default {
  components: { ButtonComponent },
  methods: {
    goHome() {
      this.$router.push('/dashboard/overview/');
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  max-height: 80vh;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}

/* Responsive design */
@media (min-width: 768px) {
  .container {
    max-width: 40%;
  }
}
</style>
