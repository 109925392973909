<template>
  <div class="flex justify-center items-center bg-gray-100 min-h-screen">
    <div class="container bg-[#eeeeee] text-white p-8 shadow-lg rounded-lg overflow-y-auto">
      <h2 v-if="this.emailStatus === 'true'" class="text-6xl text-green-500 font-bold mb-4 text-center">Email Verified</h2>
      <h2 v-if="this.emailStatus !== 'true'" class="text-6xl text-red-500 font-bold mb-4 text-center">Invalid Verification Link</h2>
      <p v-if="this.emailStatus === 'true'" class="text-center text-lg text-black mb-6">
        Your email has been successfully verified.
      </p>
      <p v-else class="text-center text-lg text-black mb-6">
        The verification link is invalid or has expired.
      </p>
      <ButtonComponent
          @click="goHome"
          text="Go Home"
        >Back to Login</ButtonComponent>

    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
export default {
  components: { ButtonComponent },
  data() {
    return {
      emailStatus: this.$route.params.emailStatus || 'false'
    };
  },
  methods: {
    goHome() {
      this.$router.push('/dashboard/overview/');
    }
  },
  created() {
    console.log(this.emailStatus);
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  max-height: 80vh;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}

/* Responsive design */
@media (min-width: 768px) {
  .container {
    max-width: 40%;
  }
}
</style>