<template>
  <div class="min-h-screen flex items-center justify-center bg-[#eee]">
    <div class="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded shadow-md">
<!--      <LanguageSelector />-->
      <img src="../assets/logo.png" alt="EsoftAffiliates logo" class="h-12 w-12 mx-auto block" />
      <h2 class="text-2xl text-white font-bold text-center">{{ ($translate('register.title')) }}</h2>

      <!-- Progress Indicator -->
      <div class="flex justify-between items-center mb-4">
        <div class="flex-1 h-2 bg-gray-500 rounded" :class="step >= 1 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 2 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 3 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step === 4 ? 'bg-orange-500' : ''"></div>
      </div>

      <form @submit.prevent="handleStep">
        <!-- Step 1: Account Details -->
        <div v-if="step === 1">

          <div class="language-selector flex items-center space-x-4">
            <div
                v-for="lang in languages"
                :key="lang.code"
                class="language-option flex items-center space-x-2 cursor-pointer transition-transform transform hover:scale-110"
                @click="changeLanguage(lang.code)"
                :class="{ active: currentLocale === lang.code }"
            >
              <img
                  :src="lang.flag"
                  :alt="lang.name + ' flag'"
                  class="w-8 h-8 rounded-full border"
              />
              <span class="text-sm font-medium text-white hover:text-gray-900">
                {{ lang.name }}
              </span>
            </div>
          </div>



          <!-- Email -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('email')) }}</label>
          <input
              type="email"
              autocomplete="email"
              v-model="email"
              @blur="checkEmailExistence"
              class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
              :class="{
                'border-red-500 focus:ring-red-500 focus:border-red-500': emailExists,
                'border-green-500 focus:ring-green-500 focus:border-green-500': !emailExists && email !== ''
              }"
              required
          />
          <!-- Show error message below the email input -->
          <p v-if="emailExists" class="text-sm text-red-500 mt-2">{{ emailError }}</p>
          <!-- Password -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('password')) }}</label>
          <input
            type="password"
            autocomplete="new-password"
            v-model="password"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Confirm Password -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('confirmPassword')) }}</label>
          <input
            type="password"
            autocomplete="new-password"
            v-model="confirmPassword"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
        </div>

        <!-- Step 2: Personal Information -->
        <div v-if="step === 2">
          <!-- First Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('firstName')) }}</label>
          <input
            type="text"
            v-model="firstName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Last Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('lastName')) }}</label>
          <input
            type="text"
            v-model="lastName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Address -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('address')) }}</label>
          <input
            type="text"
            v-model="address"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- City -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('city')) }}</label>
          <input
            type="text"
            v-model="city"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Postal Code -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('postalCode')) }}</label>
          <input
            type="text"
            v-model="postalCode"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <CountrySelectComponent
              v-model="country"
          />
        </div>

        <!-- Step 3: Company Information -->
        <div v-if="step === 3">
          <!-- Company Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('companyName')) }}</label>
          <input
            type="text"
            v-model="companyName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Website URL -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('websiteURL')) }}</label>
          <input
            type="text"
            v-model="websiteURL"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Brands -->
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('register.step3.brands')) }}</label>
          <input
            type="text"
            v-model="brands"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
        </div>

        <!-- Step 4: Email Verification -->
        <div v-if="step === 4" class="text-center text-white">
          <p class="mb-3">{{ ($translate('register.progress.emailVerification')) }}</p>
          <button class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" @click.prevent="resendVerification">{{ ($translate('register.progress.resendVerification')) }}</button>
        </div>

        <!-- Step Navigation Buttons -->
        <div v-if="step < 4" class="flex justify-between mt-4">
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" v-if="step > 1" @click.prevent="step--">{{ ($translate('back')) }}</button>
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" v-if="step < 3" type="submit">{{ ($translate('next')) }}</button>
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" @disabled="isRegistering" v-if="step === 3" type="submit">{{ ($translate('submit')) }}</button>
        </div>
      </form>

      <p class="text-center text-sm text-gray-400">
        {{ ($translate('register.loginPrompt.text')) }}
        <router-link to="/login" class="text-orange-500 hover:underline">{{ ($translate('register.loginPrompt.linkText')) }}</router-link>
      </p>

      <!-- Error dialog -->
      <ModalDialogComponent
          :title="modalTitle"
          :message="modalMessage"
          :visible="showModal"
          @close="showModal = false"
      />

    </div>
  </div>
</template>

<script>
import InputField from '@/components/Form/InputFieldComponent.vue';
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import LanguageSelector from "@/components/Fields/LanguageSelector.vue";
import {ref} from "vue";
import CountrySelectComponent from "@/components/Form/CountrySelectComponent.vue";


let secret = "657f8b8da628ef83cf69101b6817150a";
const email = ref("");

export default {
  components: {
    CountrySelectComponent,
    LanguageSelector,
    InputField,
    ButtonComponent,
    ModalDialogComponent,
  },
  data() {
    return {
      step: 1,
      email: '',
      password: '',
      confirmPassword: '',
      emailExists: false,
      companyName: '',
      firstName: '',
      lastName: '',
      locale: 'en',
      address: '',
      city: '',
      postalCode: '',
      isRegistering: false,
      country: '',
      websiteURL: '',
      brands: '',
      showModal: false,
      modalMessage: '',
      modalTitle: '',
      emailError: '',
      languages: [
        {code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png'},
        {code: 'el', name: 'Greek', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1920px-Flag_of_Greece.svg.png'},
      ],
      currentLocale: this.$i18n.locale,
    };
  },
  watch: {
    country(newValue) {
      this.country = newValue;
    },
  },
  methods:
  {
    changeLanguage(code) {
      this.$i18n.locale = code;
      this.currentLocale = code;
      this.locale = code;
    },
    async checkEmail(email) {
      try {
        let response = await this.$axios.request({
          url: '/register/check_email/',
          method: 'POST',
          data: {
            email: email,
          },
        });
        return response.data.status === 'error';
      } catch (error) {
        console.error('Error checking email:', error);
        return false;
      }
    },
    async validateEmail() {
      if (!this.email.includes('@')) {
        this.emailError = this.$translate('register.error.invalidEmail');
        this.emailExists = true;
      } else {
        try {
          const emailExists = await this.checkEmail(this.email);
          this.emailError = emailExists
              ? this.$translate('register.error.emailExists')
              : '';
        } catch (error) {
          console.error('Error validating email:', error);
          this.emailError = this.$translate('register.error.emailCheckFailed');
        }
      }
    },
    handleStep() {
      if (this.step === 1) {
        if(this.emailExists){
          return;
        }

        if(!this.email || !this.email.includes('@')){
          this.showModal = true;
          this.modalTitle = this.$translate('register.error.defaultTitle');
          this.modalMessage = this.$translate('register.error.invalidEmail');
          return;
        }


        if (!this.password || this.password.length < 8) {
          this.showModal = true;
          this.modalTitle = this.$translate('register.error.defaultTitle');
          this.modalMessage = this.$translate('register.error.passwordTooShort');
          return;
        }


        if (this.password !== this.confirmPassword) {
          this.showModal = true;
          this.modalTitle = this.$translate('register.error.defaultTitle');
          this.modalMessage = this.$translate('register.error.passwordsMatch');
          return;
        }
        console.log('Step 1 data:', {
          email: this.email,
          password: this.password,
          locale: this.locale,
        });
        this.step++;
      } else if (this.step === 2) {
        if (!this.firstName || !this.lastName || !this.address || !this.city || !this.postalCode || !this.country) {
          this.showModal = true;
          this.modalTitle = this.$translate('register.error.defaultTitle');
          this.modalMessage = this.$translate('register.error.missingField');
          return;
        }
        console.log('Step 2 data:', {
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
        });

        this.step++;
      } else if (this.step === 3) {

        if (!this.companyName || !this.websiteURL || !this.brands) {
          this.showModal = true;
          this.modalTitle = this.$translate('register.error.defaultTitle');
          this.modalMessage = this.$translate('register.error.missingField');
          return;
        }

        console.log('Step 3 data:', {
          companyName: this.companyName,
          websiteURL: this.websiteURL,
          brands: this.brands,
        });

        this.submitRegistration();
      }
    },
    async checkEmailExistence() {

      if (!this.email.includes('@')) {
        this.emailError = this.$translate('register.error.invalidEmail');
        this.emailExists = true;
        return;
      }

      try {
        const emailExists = await this.checkEmail(this.email);
        this.emailExists = emailExists;
        this.emailError = emailExists
            ? this.$translate('register.error.emailExists')
            : '';
      } catch (error) {
        console.error('Error checking email existence:', error);
        this.emailError = this.$translate('register.error.emailCheckFailed');
      }
    },
    async submitRegistration() {
      if(this.isRegistering) return;
      this.isRegistering = true;
      const payload = {
        email: this.email,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        address: this.address,
        city: this.city,
        locale: this.locale,
        postal_code: this.postalCode,
        country: this.country,
        company_name: this.companyName,
        website_url: this.websiteURL,
        brands: this.brands,
        //email_hash: CryptoJS.HmacSHA256(this.email, secret).toString(),
      };

      try {
        let response = await this.$axios.request({
          url: '/register/',
          method: 'POST',
          data: payload,
        });
        this.$toast.success(this.$translate('register.success'));
      } catch (error) {
        this.$toast.error(this.$translate('register.failed'));
      }
      this.step = 4;
    },
    async resendVerification() {
      try {
        let response = await this.$axios.request({
          url: '/user/resend_verification/',
          method: 'POST',
          data: {
            email: this.email,
          },
        });
        if (response.status === 200) {
          this.$toast.success(this.$translate('register.verificationSent'));
        } else {
          this.$toast.error(this.$translate('register.verificationFailed'));
        }
      } catch (error) {
        console.error('Error resending verification:', error);
        this.$toast.error(this.$translate('register.verificationFailed'));
      }
    },
  },
};
</script>

<style scoped>
/* Language Selector Start */

.language-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background: rgb(45, 45, 45);
  border-radius: 8px;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.language-option img {
  transition: box-shadow 0.3s ease;
}

.language-option.active {
  background: rgb(45, 45, 45);
  color: white;
}

.language-option.active img {
  box-shadow: 0 0 10px #4caf50;
}

.language-option span {
  transition: color 0.3s ease;
}

.language-option:hover span {
  color: #f16f24;
}

/* Language Selector End */
</style>
