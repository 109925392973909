import { createRouter, createWebHistory } from 'vue-router';
import axios from '../module/axios.js';

import Login from '../views/LoginPage.vue';
import Register from '../views/RegisterPage.vue';
import ForgotPassword from '../views/ForgotPasswordPage.vue';
import ResetPassword from '../views/ResetPasswordPage.vue';
import Dashboard from '../views/dashboard/DashboardOverviewPage.vue';
import Inbox from '../views/dashboard/InboxPage.vue';
import Notification from '../views/dashboard/NotificationPage.vue';
import Users from '../views/users/UsersPage.vue';
import UsersCreate from "../views/users/UserCreatePage.vue";
import BrandsCreate from "../views/brands/CreateBrandsPage.vue";
import BrandsManage from "../views/brands/ManageBrandsPage.vue";
import UserProfile from '../views/users/UserProfilePage.vue';
import DepositPostback from '../views/postbacks/DepositPostbackPage.vue';
import RegistrationPostback from '../views/postbacks/RegistrationPostbackPage.vue';
import UserGroups from "../views/users/UserGroupsPage.vue";
import UserPermissions from "../views/users/UserPermissions.vue";
import Affiliates from '../views/affiliates/AffiliatesPage.vue';
import PendingAffiliates from "../views/affiliates/PendingAffiliatesPage.vue";
import AffiliateStats  from "../views/affiliates/AffiliateStatsPage.vue";
import MediaLibrary from "../views/media/MediaLibraryPage.vue";
import ActiveBanners from "../views/media/ActiveBannersPage.vue";
import UploadMedia from "../views/media/UploadMediaPage.vue";
import Campaigns from "../views/affiliates/CampaignPage.vue";
import BannerGenerator from "../views/media/BannerGeneratorPage.vue";
import ManageCommissions from "../views/commission/ManageCommissionsPage.vue";
import CommissionsCreate from "../views/commission/CommissionCreate.vue";
import ManageContracts from "../views/commission/ManageContractsPage.vue";
import ContractCreate from "../views/commission/ContractCreate.vue";
import AddSource from "../views/sources/SourceCreatePage.vue";
import ManageSources from "../views/sources/ManageSourcesPage.vue";
import AddSite from "../views/sites/SiteCreatePage.vue";
import ManageSites from "../views/sites/ManageSitesPage.vue";
import AddProduct from "../views/products/ProductCreatePage.vue";
import ManageProducts from "../views/products/ManageProductsPage.vue";
import WhitelabelSettings from "../views/settings/WhitelabelSettingsPage.vue";
import PayoutHistory from "../views/payouts/PayoutHistoryPage.vue";
import ViewPayout from "../views/payouts/ViewPayoutPage.vue";
import NotFoundPage from "../views/404Page.vue";

import EmailVerificationStatus from "@/views/EmailVerificationStatus.vue";

// Define the routes for the application
const routes = [
  { path: '/redirect/tracking_not_found', component: NotFoundPage },
  { path: '/forgot_password', component: ForgotPassword },
  { path: '/reset_password/:token', component: ResetPassword },
  { path: '/login', component: Login },
  { path: '/', component: Login },
  { path: '/register', component: Register },
  { path: '/dashboard/overview', component: Dashboard },
  { path: '/dashboard/profile', component: UserProfile },
  { path: '/dashboard/inbox', component: Inbox },
  { path: '/dashboard/notifications', component: Notification },
  { path: '/verify/:hash',
    redirect: (to) => {
      const hash = to.params.hash;
      window.location.href =  `https://api-affiliate.web24.me/verify/token/${hash}`;
    }
  },
  {
    path: '/verification/:emailStatus',
    component: EmailVerificationStatus,
  },
  { path: '/dashboard/users', component: Users },
  { path: '/dashboard/users/create', component: UsersCreate },
  { path: '/dashboard/users/groups', component: UserGroups },
  { path: '/dashboard/brands/create', component: BrandsCreate },
  { path: '/dashboard/brands/manage', component: BrandsManage },
  { path: '/dashboard/users/permissions', component: UserPermissions },
  { path: '/dashboard/affiliates/manage', component: Affiliates },
  { path: '/dashboard/affiliates/pending', component: PendingAffiliates },
  { path: '/dashboard/affiliates/stats', component: AffiliateStats},
  { path: '/dashboard/affiliates/campaigns', component: Campaigns },
  { path: '/dashboard/media/upload', component: UploadMedia },
  { path: '/dashboard/media/library', component: MediaLibrary },
  { path: '/dashboard/media/banners', component: BannerGenerator },
  { path: '/dashboard/commission/manage', component: ManageCommissions },
  { path: '/dashboard/commission/create', component: CommissionsCreate },
  { path: '/dashboard/commission/contracts/create', component: ContractCreate },
  { path: '/dashboard/commission/contracts/manage', component: ManageContracts },
  { path: '/dashboard/sources/manage', component: ManageSources },
  { path: '/dashboard/sources/add', component: AddSource },
  { path: '/dashboard/sites/manage', component: ManageSites },

  { path: '/dashboard/postbacks/registrations', component: RegistrationPostback },
  { path: '/dashboard/postbacks/deposits', component: DepositPostback },

  { path: '/dashboard/sites/add', component: AddSite },
  { path: '/dashboard/products/create', component: AddProduct },
  { path: '/dashboard/products/manage' , component: ManageProducts },
  { path: '/dashboard/settings/whitelabel', component: WhitelabelSettings },
  { path: '/dashboard/payouts/history', component: PayoutHistory },
  { path: '/dashboard/payouts/view', component: ViewPayout },
  { path: '/redirect/:tracking_id',
    redirect: (to) => { const trackingId = to.params.tracking_id;
      //return `https://affiliateapi.web24.me/redirect/index/${trackingId}`;
      const referrer = document.referrer;
      window.location.href = `https://api-affiliate.web24.me/redirect/index/${trackingId}?referrer=${referrer}`;
    }},


  { path: "/:catchAll(.*)", component: NotFoundPage }
];
// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Helper function to check if the user is authenticated
async function isAuthenticated() {
  const token = localStorage.getItem('token') || '';
  if (token === '') {
    return false;
  }
  try {
    let response = await axios.request({
      url: '/auth',
      method: 'POST',
    });


    return response.data.status === 'success';
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Before each route navigation
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token') || null;
  const isValidAuth = token ? await isAuthenticated() : false;
  const regex = /^\/reset_password\/([^/]+)$/;

  if(regex.test(to.path) || to.path === '/redirect/tracking_not_found') {
    return next();
  }
  console.log('FROM:',from.path);
  console.log('TO:',to.path);



  // Remove token if authentication is invalid
  if (!isValidAuth) {
    localStorage.removeItem('token');
    axios.defaults.headers.Authorization = null;
  }

  // Redirect unauthenticated users to login page unless they are on specific pages
  if (!isValidAuth && !['/login', '/register', '/forgot_password', '/reset_password', '/verification/true', 'verification/false'].includes(to.path)) {
    return next('/login');
  }

  // Prevent infinite redirection between login and forgot/reset password pages
  if ((from.path === '/login' && to.path === '/forgot_password') || (from.path === '/forgot_password' && to.path === '/login')) {
    return next(); // Allow navigation without redirecting again
  }

  if ((from.path === '/login' && to.path === '/reset_password/123') || (from.path === '/reset_password' && to.path === '/login')) {
    return next(); // Allow navigation without redirecting again
  }

  // Redirect authenticated users accessing root or login to the dashboard
  if (isValidAuth && ['/login', '/'].includes(to.path)) {
    return next('/dashboard/overview');
  }

  // Proceed with navigation
  next();
});

// After each route navigation (optional)
router.afterEach(async (to, from) => {
  // Handle any post-navigation logic if needed
});

export default router;