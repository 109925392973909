export const ADMIN_RESOURCES = [
    {
        title: "Dashboard",
        icon: "dashboard",
        subItems: [{ name: "Overview", path: "/dashboard/overview" }],
    },
    {
        title: "Users",
        icon: "people",
        subItems: [
            { name: "Create User", path: "/dashboard/users/create" },
            { name: "Manage Users", path: "/dashboard/users" },
        ],
    },
    {
        title: "Brands",
        icon: "branding_watermark",
        subItems: [
            { name: "Create Brand", path: "/dashboard/brands/create" },
            { name: "Manage Brands", path: "/dashboard/brands/manage" },
        ],
    },
    {
        title: "Affiliates",
        icon: "work",
        subItems: [
            { name: "Manage Affiliates", path: "/dashboard/affiliates/manage" },
            { name: "Pending Affiliates", path: "/dashboard/affiliates/pending" },
            { name: "Campaigns", path: "/dashboard/affiliates/campaigns" },
        ],
    },
    {
        title: "Media",
        icon: "photo_library",
        subItems: [
            { name: "Upload Media", path: "/dashboard/media/upload" },
            { name: "Media Library", path: "/dashboard/media/library" },
        ],
    },
    {
        title: "Contracts",
        icon: "attach_money",
        subItems: [
            { name: "Add new Contracts", path: "/dashboard/commission/contracts/create" },
            { name: "Manage Contracts", path: "/dashboard/commission/contracts/manage" },
        ],
    },
    {
        title: "Sources",
        icon: "location_on",
        subItems: [
            { name: "Add Sources", path: "/dashboard/sources/add" },
            { name: "Manage Sources", path: "/dashboard/sources/manage" },
        ],
    },
    {
        title: "Products",
        icon: "shopping_cart",
        subItems: [
            { name: "Add Product", path: "/dashboard/products/create" },
            { name: "Manage Products", path: "/dashboard/products/manage" },
        ],
    },
    {
        title: "Sites",
        icon: "web",
        subItems: [{ name: "Add Site", path: "/dashboard/sites/add" }, { name: "Manage Sites", path: "/dashboard/sites/manage" }],
    },
    {
        title: "PostBacks",
        icon: "sync ",
        subItems: [{ name: "Registrations", path: "/dashboard/postbacks/registrations" }, { name: "Deposits", path: "/dashboard/postbacks/deposits" }],
    },
    {
        title: "Settings",
        icon: "settings",
        subItems: [
            { name: "Profile", path: "/dashboard/profile" },
        ],
    },
];

export const AFFILIATE_RESOURCES = [
    {
        title: "Affiliates",
        icon: "work",
        subItems: [{ name: "Campaigns", path: "/dashboard/affiliates/campaigns" }],
    },
    {
        title: "Banners",
        icon: "photo_library",
        subItems: [
            { name: "Media Library", path: "/dashboard/media/library" },
        ],
    },
    {
        title: "Contracts",
        icon: "attach_money",
        subItems: [{ name: "Manage Contracts", path: "/dashboard/commission/contracts/manage" }],
    },
    {
        title: "Sites",
        icon: "web",
        subItems: [{ name: "Add Site", path: "/dashboard/sites/add" }, { name: "Manage Sites", path: "/dashboard/sites/manage" }],
    },
    {
        title: "Settings",
        icon: "settings",
        subItems: [{ name: "Profile", path: "/dashboard/profile" }],
    },
];

export const CLIENT_RESOURCES = [
    {
        title: "Brands",
        icon: "branding_watermark",
        subItems: [
            { name: "Create Brand", path: "/dashboard/brands/create" },
            { name: "Manage Brands", path: "/dashboard/brands/manage" },
        ],
    },
    {
        title: "Media",
        icon: "photo_library",
        subItems: [
            { name: "Upload Media", path: "/dashboard/media/upload" },
            { name: "Media Library", path: "/dashboard/media/library" },
            { name: "Campaigns", path: "/dashboard/affiliates/campaigns" },
        ],
    },
    {
        title: "Contracts",
        icon: "attach_money",
        subItems: [
            { name: "Add new Contracts", path: "/dashboard/commission/contracts/create" },
            { name: "Manage Contracts", path: "/dashboard/commission/contracts/manage" },
        ],
    },
    {
        title: "Sources",
        icon: "location_on",
        subItems: [
            { name: "Add Sources", path: "/dashboard/sources/add" },
            { name: "Manage Sources", path: "/dashboard/sources/manage" },
        ],
    },
    {
        title: "Products",
        icon: "shopping_cart",
        subItems: [
            { name: "Add Product", path: "/dashboard/products/create" },
            { name: "Manage Products", path: "/dashboard/products/manage" },
        ],
    },
    {
        title: "Settings",
        icon: "settings",
        subItems: [{ name: "Profile", path: "/dashboard/profile" }],
    },
];

